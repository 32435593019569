import {
  deepFlat,
  entries,
  filter,
  find,
  flatten,
  go,
  head,
  last,
  map,
  object,
  pluck,
  reduce,
  reject,
  sel,
  sortBy,
  sortByDesc,
  sumBy,
  uniqBy,
} from 'fxjs/es';
import { PriceS } from '../../Price/S/Function/module/PriceS.js';
import { getPrintDiscountRanges, getProductDiscountRanges } from '../../ProductColor/S/DiscountRanges.js';
import { rowTo_CollaboType, rowToCollaboType } from '../../Util/S/LangAndCollaboType.js';
import { VectorEditorConstantS } from '../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { makeSaleManager } from './makeSaleManager.js';
import { UtilS } from '../../Util/S/Function/module/UtilS.js';
import { BpDiscountSetConstantS } from '../../BpDiscountSet/S/Constant/module/BpDiscountSetConstantS.js';
import { BpDiscountSetWowBpDiscountSetS } from '../../BpDiscountSet/WowBpDiscountSet/S/Function/module/BpDiscountSetWowBpDiscountSetS.js';
import { BpOptionS } from '../../../modules/BpOption/S/Function/module/BpOptionS.js';
import { BpOptionKeyRingS } from '../../BpOption/KeyRing/S/Function/module/BpOptionKeyRingS.js';
import { BpOptionAcrylicFigureS } from '../../BpOption/AcrylicFigure/S/Function/module/BpOptionAcrylicFigureS.js';

function calcPrice(product_color, _en) {
  _en = _en || '';
  return PriceS.add(calcProductPrice(product_color, _en), calcPrintPrice(product_color, _en));
}

export function makeProductColorPrice(product_color) {
  const price_info = calcPriceInfo(product_color);
  return {
    price_info,
    price: price_info.sizes_prices[product_color.base_product_size_id]['price' + ''],
    price_en: price_info.sizes_prices[product_color.base_product_size_id]['price' + '_en'],
    price_jp: price_info.sizes_prices[product_color.base_product_size_id]['price' + '_jp'],
  };
}
export function resetProductColorPrice(product_color) {
  // if (product_color._.base_product._?.bp_discount_set?.type === BpDiscountSetConstantS.TYPE.MP_WOW) return;
  product_color.original_price = 0; // todo ?
  product_color.price_info = calcPriceInfo(product_color) || 0;
  product_color.price = calcSizePrice(product_color, '') || 0;
  product_color.price_en = calcSizePrice(product_color, '_en') || 0;
  product_color.price_jp = calcSizePrice(product_color, '_jp') || 0;
}

export const getPriceInfo = (product_color) => {
  return calcPriceInfo(product_color) || 0;
};

export const isMinusPrintPrice = (product_color) => {
  if (
    [VectorEditorConstantS.KEYRING_EDITOR, VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR].includes(
      product_color._.base_product.maker_type,
    )
  ) {
    return;
  }
  const print_price = Number(calcPrintPrice(product_color));
  const print_price_en = Number(calcPrintPrice(product_color, '_en'));
  const print_price_jp = Number(calcPrintPrice(product_color, '_jp'));
  return print_price < 0 || print_price_en < 0 || print_price_jp < 0;
};

function tempOptionPrice(selected_bp_option_ids, _en = '') {
  try {
    if (!selected_bp_option_ids?.length) return 0;
    const bp_options = [
      {
        bp_option_id: 946,
        price: 400,
        price_en: 0.36,
        price_jp: 40,
      },
      {
        bp_option_id: 945,
        price: 1600,
        price_en: 1.46,
        price_jp: 157,
      },
      {
        bp_option_id: 944,
        price: 1000,
        price_en: 0.91,
        price_jp: 98,
      },
      {
        bp_option_id: 943,
        price: 1400,
        price_en: 1.27,
        price_jp: 137,
      },
      {
        bp_option_id: 768,
        price: 1000,
        price_en: 0.91,
        price_jp: 98,
      },
    ];
    return (
      go(
        bp_options,
        filter((bp_option) => selected_bp_option_ids.includes(bp_option.bp_option_id)),
        sumBy((bp_option) => Number(bp_option['price' + _en])),
      ) || 0
    );
  } catch (e) {
    return 0;
  }
}
/* hard coding 되어 있는 구간 할인으로 적용 */
function systemRangeCalcPriceInfo(product_color) {
  product_color.bp_discount_set_id = null;
  const _en = '_en';
  const _jp = '_jp';
  const collabo_type = rowToCollaboType(product_color);
  const _collabo_type = rowTo_CollaboType(product_color);
  const selected_bp_option_ids = product_color._.selected_option_group?.bp_option_ids;
  const selected_bp_option_price = tempOptionPrice(selected_bp_option_ids);
  const selected_bp_option_price_en = tempOptionPrice(selected_bp_option_ids, _en);
  const selected_bp_option_price_jp = tempOptionPrice(selected_bp_option_ids, _jp);
  const price = PriceS.add(calcPrice(product_color), selected_bp_option_price);
  const price_en = PriceS.add(calcPrice(product_color, _en), selected_bp_option_price_en);
  const price_jp = PriceS.add(calcPrice(product_color, _jp), selected_bp_option_price_jp);
  const product_price = calcProductPrice(product_color);
  const product_price_en = calcProductPrice(product_color, _en);
  const product_price_jp = calcProductPrice(product_color, _jp);
  const print_price = PriceS.add(calcPrintPrice(product_color), selected_bp_option_price);
  const print_price_en = PriceS.add(calcPrintPrice(product_color, _en), selected_bp_option_price_en);
  const print_price_jp = PriceS.add(calcPrintPrice(product_color, _jp), selected_bp_option_price_jp);
  const product_oem_price = calcProductOemPrice(product_color);
  const print_oem_price = calcPrintOemPrice(product_color);

  const bp = product_color._.base_product;
  const product_option_prices = sel(`price_info.product_option_prices`, product_color);
  return {
    price,
    price_en,
    price_jp,
    product_price,
    product_price_en,
    product_price_jp,
    print_price,
    print_price_en,
    print_price_jp,
    product_oem_price,
    print_oem_price,
    product_option_prices,
    sizes_prices: object(
      map(function (size) {
        const size_price = product_color.collabo_type == 'line' ? 0 : size.price || 0;
        const size_oem_price = size['oem_price' + _collabo_type] || 0;
        const size_price_en = parseFloat(product_color.collabo_type == 'line' ? 0 : size.price_en || 0);
        const size_price_jp = product_color.collabo_type == 'line' ? 0 : size.price_jp || 0;

        return [
          size.id,
          {
            id: size.id,
            name: size.name,
            price: PriceS.add(size_price, price),
            price_en: PriceS.add(size_price_en, price_en),
            price_jp: PriceS.add(size_price_jp, price_jp),
            product_price,
            product_price_en,
            product_price_jp,
            print_price,
            print_price_en,
            print_price_jp,
            size_price,
            size_price_en,
            size_price_jp,
            oem_price: PriceS.add(product_oem_price, print_oem_price, size_oem_price),
            product_oem_price,
            print_oem_price,
            size_oem_price,
            discounted_product_prices: object(
              map(function (range) {
                return [
                  `${range.end}`,
                  discountProductPrice(
                    collabo_type,
                    range.start,
                    PriceS.add(product_price, size_price),
                    bp['percent' + _collabo_type],
                    'kr',
                  ),
                ];
              }, getProductRanges(collabo_type)),
            ),
            discounted_product_price_ens: object(
              map(function (range) {
                return [
                  `${range.end}`,
                  discountProductPrice(
                    collabo_type,
                    range.start,
                    PriceS.add(product_price_en, size_price_en),
                    bp['percent' + _collabo_type + _en],
                    'en',
                  ),
                ];
              }, getProductRanges(collabo_type)),
            ),
            discounted_product_price_jps: object(
              map(function (range) {
                return [
                  `${range.end}`,
                  discountProductPrice(
                    collabo_type,
                    range.start,
                    PriceS.add(product_price_jp, size_price_jp),
                    bp['percent' + _collabo_type + _jp],
                    'jp',
                  ),
                ];
              }, getProductRanges(collabo_type)),
            ),
            discounted_print_prices: object(
              map(function (range) {
                return [
                  `${range.end}`,
                  discountPrintPrice(
                    collabo_type,
                    range.start,
                    PriceS.price(print_price),
                    bp['print_percent' + _collabo_type],
                    'kr',
                  ),
                ];
              }, getPrintRanges(collabo_type)),
            ),
            discounted_print_price_ens: object(
              map(function (range) {
                return [
                  `${range.end}`,
                  discountPrintPrice(
                    collabo_type,
                    range.start,
                    PriceS.price(print_price_en),
                    bp['print_percent' + _collabo_type + _en],
                    'en',
                  ),
                ];
              }, getPrintRanges(collabo_type)),
            ),
            discounted_print_price_jps: object(
              map(function (range) {
                return [
                  `${range.end}`,
                  discountPrintPrice(
                    collabo_type,
                    range.start,
                    PriceS.price(print_price_jp),
                    bp['print_percent' + _collabo_type + _jp],
                    'jp',
                  ),
                ];
              }, getPrintRanges(collabo_type)),
            ),
          },
        ];
      }, bp._.base_product_sizes),
    ),
  };
}
const getBasicPrice = (discounted_prices) =>
  go(
    discounted_prices,
    entries,
    sortBy(([end]) => parseInt(end)),
    head,
    (range_price) => range_price[1] || 0,
  );

function dbRangeCalcPriceInfo(product_color) {
  const _en = '_en';
  const _jp = '_jp';
  const bp_discount_set = product_color._.base_product._.bp_discount_set;

  const _collabo_type = rowTo_CollaboType(product_color);

  const bp_discount_ranges = bp_discount_set?._?.bp_discount_ranges;
  product_color.bp_discount_set_id =
    bp_discount_set?.id && bp_discount_ranges?.length ? bp_discount_set.id : null;
  const selected_bp_option_ids = product_color._.selected_option_group?.bp_option_ids;

  const product_oem_price = calcProductOemPrice(product_color);
  const print_oem_price = calcPrintOemPrice(product_color);

  const bp = product_color._.base_product;
  const product_option_prices = sel(`price_info.product_option_prices`, product_color);
  const designed_faces = getDesignedFaces(product_color);

  const discounted_product_prices = makeDiscountPrices({ lang: 'kr' }, bp_discount_ranges);
  const discounted_product_price_ens = makeDiscountPrices({ lang: 'en' }, bp_discount_ranges);
  const discounted_product_price_jps = makeDiscountPrices({ lang: 'jp' }, bp_discount_ranges);
  const product_price = getBasicPrice(discounted_product_prices);
  const product_price_en = getBasicPrice(discounted_product_price_ens);
  const product_price_jp = getBasicPrice(discounted_product_price_jps);

  const discounted_print_prices = makeDiscountFacePrices(
    { lang: 'kr' },
    {
      product_color,
      designed_faces,
    },
    bp_discount_ranges,
  );
  const discounted_print_price_ens = makeDiscountFacePrices(
    { lang: 'en' },
    {
      product_color,
      designed_faces,
    },
    bp_discount_ranges,
  );
  const discounted_print_price_jps = makeDiscountFacePrices(
    { lang: 'jp' },
    {
      product_color,
      designed_faces,
    },
    bp_discount_ranges,
  );
  const print_price = getBasicPrice(discounted_print_prices);
  const print_price_en = getBasicPrice(discounted_print_price_ens);
  const print_price_jp = getBasicPrice(discounted_print_price_jps);
  const discounted_option_prices = makeDiscountBpOptionPrices(
    { lang: 'kr' },
    selected_bp_option_ids,
    bp_discount_ranges,
  );
  const discounted_option_price_ens = makeDiscountBpOptionPrices(
    { lang: 'en' },
    selected_bp_option_ids,
    bp_discount_ranges,
  );
  const discounted_option_price_jps = makeDiscountBpOptionPrices(
    { lang: 'jp' },
    selected_bp_option_ids,
    bp_discount_ranges,
  );
  const selected_bp_options_price = getBasicPrice(discounted_option_prices);
  const selected_bp_options_price_en = getBasicPrice(discounted_option_price_ens);
  const selected_bp_options_price_jp = getBasicPrice(discounted_option_price_jps);
  const price = PriceS.add(product_price, print_price, selected_bp_options_price);
  const price_en = PriceS.add(product_price_en, print_price_en, selected_bp_options_price_en);
  const price_jp = PriceS.add(product_price_jp, print_price_jp, selected_bp_options_price_jp);
  return {
    price,
    price_en,
    price_jp,
    product_price,
    product_price_en,
    product_price_jp,
    print_price,
    print_price_en,
    print_price_jp,
    selected_bp_options_price,
    selected_bp_options_price_en,
    selected_bp_options_price_jp,
    /*아래 3개는 레거시 인듯*/
    product_oem_price,
    print_oem_price,
    product_option_prices,
    discounted_product_prices,
    discounted_product_price_ens,
    discounted_product_price_jps,
    discounted_print_prices,
    discounted_print_price_ens,
    discounted_print_price_jps,
    discounted_option_prices,
    discounted_option_price_ens,
    discounted_option_price_jps,
    sizes_prices: object(
      map(function (size) {
        const size_price = product_color.collabo_type == 'line' ? 0 : size.price || 0;
        const size_oem_price = size['oem_price' + _collabo_type] || 0;
        const size_price_en = parseFloat(product_color.collabo_type == 'line' ? 0 : size.price_en || 0);
        const size_price_jp = product_color.collabo_type == 'line' ? 0 : size.price_jp || 0;
        return [
          size.id,
          {
            id: size.id,
            name: size.name,
            price: PriceS.add(size_price, price),
            price_en: PriceS.add(size_price_en, price_en),
            price_jp: PriceS.add(size_price_jp, price_jp),
            product_price,
            product_price_en,
            product_price_jp,
            print_price,
            print_price_en,
            print_price_jp,
            size_price,
            size_price_en,
            size_price_jp,
            selected_bp_options_price,
            selected_bp_options_price_en,
            selected_bp_options_price_jp,
            oem_price: PriceS.add(product_oem_price, print_oem_price, size_oem_price),
            product_oem_price,
            print_oem_price,
            size_oem_price,
            discounted_size_prices: makeDiscountSizePrices(
              { lang: 'kr' },
              { base_product_size_id: size.id },
              bp_discount_ranges,
            ),
            discounted_size_price_ens: makeDiscountSizePrices(
              { lang: 'en' },
              { base_product_size_id: size.id },
              bp_discount_ranges,
            ),
            discounted_size_price_jps: makeDiscountSizePrices(
              { lang: 'jp' },
              { base_product_size_id: size.id },
              bp_discount_ranges,
            ),
          },
        ];
      }, bp._.base_product_sizes),
    ),
  };
}

function wowCalcPriceInfo(product_color) {
  // const _en = '_en';
  // const _jp = '_jp';
  const bp_discount_set = product_color._.base_product._.bp_discount_set;

  const bp_discount_ranges = go(
    bp_discount_set?._?.bp_discount_ranges,
    BpDiscountSetWowBpDiscountSetS.reduceUniquePriceBpDiscountRanges,
  );

  product_color.bp_discount_set_id =
    bp_discount_set?.id && bp_discount_ranges?.length ? bp_discount_set.id : null;

  const bp = product_color._.base_product;
  const discounted_product_prices = makeDiscountPrices({ lang: 'kr' }, bp_discount_ranges);
  const discounted_product_price_ens = makeDiscountPrices({ lang: 'en' }, bp_discount_ranges);
  const discounted_product_price_jps = makeDiscountPrices({ lang: 'jp' }, bp_discount_ranges);
  const price = getBasicPrice(discounted_product_prices);
  const price_en = getBasicPrice(discounted_product_price_ens);
  const price_jp = getBasicPrice(discounted_product_price_jps);

  return {
    price,
    price_en,
    price_jp,
    product_price: price,
    product_price_en: price_en,
    product_price_jp: price_jp,
    print_price: 0,
    print_price_en: 0,
    print_price_jp: 0,
    sizes_prices: object(
      map(function (size) {
        return [
          size.id,
          {
            id: size.id,
            name: size.name,
            price,
            price_en,
            price_jp,
            product_price: price,
            product_price_en: price_en,
            product_price_jp: price_jp,
            print_price: 0,
            print_price_en: 0,
            print_price_jp: 0,
            size_price: 0,
            size_price_en: 0,
            size_price_jp: 0,
            discounted_product_prices,
            discounted_product_price_ens,
            discounted_product_price_jps,
          },
        ];
      }, bp._.base_product_sizes),
    ),
  };
}

export function calcPriceInfo(product_color) {
  if (product_color._.base_product._?.bp_discount_set?.is_public) {
    if (product_color._.base_product._?.bp_discount_set?.type === BpDiscountSetConstantS.TYPE.MP_WOW) {
      /* 다른 방식으로 가격 계산 */
      return wowCalcPriceInfo(product_color);
    }
    return dbRangeCalcPriceInfo(product_color);
  } else {
    return systemRangeCalcPriceInfo(product_color);
  }
}

function tempMinumumPrice(product_color, price, _en) {
  if (_en !== '') {
    return price;
  }

  const min_prices = [
    { base_product_id: 4254, name: '핀버튼', min_price: 850 },
    { base_product_id: 4255, name: '거울버튼', min_price: 1000 },
    { base_product_id: 4256, name: '거울키링', min_price: 1200 },
  ];

  return go(
    min_prices,
    find((m) => m.base_product_id == product_color.base_product_id),
    (m) => (m ? Math.max(m['min_price' + _en], price) : price),
  );
}

function baseDiscountedPrice(keys, _en) {
  return function (product_color, quantity, base_product_size_id, product_option_id, is_marpple_price) {
    quantity = quantity === undefined ? 1 : quantity;
    const price_info = product_color.price_info;
    const sizes_price = sel(
      `price_info.sizes_prices.${base_product_size_id || product_color.base_product_size_id}`,
      product_color,
    ) || {
      price: 0,
      price_en: 0,
      price_jp: 0,
    };

    const product_option_price = sel(
      `price_info.product_option_prices.${product_option_id}`,
      product_color,
    ) || {
      price: 0,
      price_en: 0,
      price_jp: 0,
    };

    if (!is_marpple_price && product_color.collabo_type == 'creator') {
      const price = tempMinumumPrice(
        product_color,
        PriceS.add(
          sizes_price['price' + _en],
          product_color['profit' + _en],
          product_option_price['price' + _en],
        ),
        _en,
      );
      return {
        original_one: price,
        discounted_one: price,
        original_total: PriceS.mult(price, quantity),
        discounted_total: PriceS.mult(price, quantity),
      };
    } else {
      const discounted_price_info = reduce(
        function (memo, key) {
          const discounted_ranges = sizes_price[key] ?? price_info[key];
          const original_one = PriceS.add(
            go(
              discounted_ranges,
              entries,
              find(function ([end, dpp]) {
                if (dpp === null) return false;
                return +end >= 1;
              }),
              last,
            ),
            product_option_price['price' + _en],
          );

          const discounted_one = PriceS.add(
            go(
              discounted_ranges,
              entries,
              find(function ([end, dpp]) {
                if (dpp === null) return false;
                return +quantity <= +end;
              }),
              last,
            ),
            product_option_price['price' + _en],
          );
          return {
            original_one: PriceS.add(memo.original_one, original_one),
            discounted_one: PriceS.add(memo.discounted_one, discounted_one),
            original_total: PriceS.add(memo.original_total, PriceS.mult(original_one, quantity)),
            discounted_total: PriceS.add(memo.discounted_total, PriceS.mult(discounted_one, quantity)),
          };
        },
        {
          original_one: 0,
          discounted_one: 0,
          original_total: 0,
          discounted_total: 0,
        },
        keys,
      );

      discounted_price_info.discounted_one = tempMinumumPrice(
        product_color,
        discounted_price_info.discounted_one,
        _en,
      );
      discounted_price_info.discounted_total = PriceS.mult(discounted_price_info.discounted_one, quantity);

      return discounted_price_info;
    }
  };
}

export function creatorPriceOfProduct(product, quantity, base_product_size_id, product_option_id) {
  return calcDiscountedPrice(T.lang, product, quantity, base_product_size_id, product_option_id, false)
    .original_total;
}

export function totalProfitOfProduct(product, quantity) {
  return PriceS.add(
    product.profit,
    PriceS.sub(
      product.price_info.price,
      discountedPrice(product, quantity, undefined, undefined, true).discounted_one,
    ),
  );
}

export const calcDiscountedPrice = (
  lang,
  product_color,
  quantity,
  base_product_size_id,
  product_option_id,
  is_marpple_price,
) => {
  if (lang == 'kr') {
    return discountedPrice(
      product_color,
      quantity,
      base_product_size_id,
      product_option_id,
      is_marpple_price,
    );
  } else if (lang == 'jp') {
    return discountedPriceJp(
      product_color,
      quantity,
      base_product_size_id,
      product_option_id,
      is_marpple_price,
    );
  } else {
    return discountedPriceEn(
      product_color,
      quantity,
      base_product_size_id,
      product_option_id,
      is_marpple_price,
    );
  }
};
export function getProductColorQuantityPrice(product_color, quantity = 1) {
  return calcDiscountedPrice(T.lang, product_color, quantity, product_color.base_product_size_id, null, true);
}

export const discountedPrice = baseDiscountedPrice(
  [
    'discounted_product_prices',
    'discounted_print_prices',
    'discounted_option_prices',
    'discounted_size_prices',
  ],
  '',
);

const discountedPriceEn = baseDiscountedPrice(
  [
    'discounted_product_price_ens',
    'discounted_print_price_ens',
    'discounted_option_price_ens',
    'discounted_size_price_ens',
  ],
  '_en',
);

const discountedPriceJp = baseDiscountedPrice(
  [
    'discounted_product_price_jps',
    'discounted_print_price_jps',
    'discounted_option_price_jps',
    'discounted_size_price_jps',
  ],
  '_jp',
);

function calcPrintPriceImagePrice(product_color, _en) {
  return (
    go(
      sel('product_faces2.value', product_color) || [],
      map((pf) => map(flattenCvObjects, pf.designs)),
      deepFlat,
      filter((design) => {
        return Number(design._data['price' + _en]) !== 0;
      }),
      uniqBy(function (design) {
        return design._data.is_embro ? new Date() : design._data.image_id + '_' + design._data.image_color_id;
      }),
      map(function (design) {
        return design._data['price' + _en] || 0;
      }),
      reduce(PriceS.add),
    ) || 0
  );
}
function calcPrintPriceOnlyPrice(product_color, _en) {
  _en = _en || '';
  const bp = product_color._.base_product;
  const bpc = product_color._.base_product_color;
  const bpcfs = bpc._.base_product_color_faces;
  const _collabo_type = rowTo_CollaboType(product_color);
  return Math.max(
    PriceS.price(bp['print_price' + _collabo_type + _en]),
    reduce(
      function (price, pf) {
        if (!sel('designs.length', pf)) return price;
        const bpcf = find(function (bpcf) {
          return pf.bpf_id == bpcf.base_product_face_id;
        }, bpcfs);
        return bpcf ? PriceS.add(price, bpcf._.base_product_face['price' + _collabo_type + _en] || 0) : price;
      },
      0,
      sel('product_faces2.value', product_color) || [],
    ),
  );
}
function getDesignedFaces(product_color) {
  const pfs = go(
    product_color.product_faces2?.value,
    filter((pf) => pf.designs.length),
  );
  if (!pfs.length) return [];
  return go(
    product_color.product_faces2.value,
    map((pf) => {
      return {
        bpf_id: pf.bpf_id,
        selected_bp_option_ids: go(pf.selected_bp_options, pluck('id')),
      };
    }),
  );
}
export function calcPrintPrice(product_color, _en) {
  _en = _en || '';

  const print_price = calcPrintPriceOnlyPrice(product_color, _en);

  const images_price = calcPrintPriceImagePrice(product_color, _en);

  return PriceS.add(print_price, images_price);
}

function calcPrintOemPrice(product_color, _en) {
  _en = _en || '';
  const bp = product_color._.base_product;
  const bpc = product_color._.base_product_color;
  const bpcfs = bpc._.base_product_color_faces;

  const _collabo_type = rowTo_CollaboType(product_color);
  const print_price = Math.max(
    PriceS.price(bp['oem_print_price' + _collabo_type + _en]),
    reduce(
      function (price, pf) {
        if (!sel('designs.length', pf)) return price;
        const bpcf = find(function (bpcf) {
          return pf.bpf_id == bpcf.base_product_face_id;
        }, bpcfs);
        return bpcf ? PriceS.add(price, bpcf._.base_product_face['oem_price' + _collabo_type + _en]) : price;
      },
      0,
      sel('product_faces2.value', product_color) || [],
    ),
  );
  const images_price = 0;
  return PriceS.add(print_price, images_price);
}

const flattenCvObjects = (d) => flatten(d.objects ? map(flattenCvObjects, d.objects) : [d]);

function calcProductPrice(product_color, _en) {
  _en = _en || '';
  const bp = product_color._.base_product;
  const _collabo_type = rowTo_CollaboType(product_color);
  return PriceS.price(bp['price' + _collabo_type + _en]);
}

function calcProductOemPrice(product_color, _en) {
  _en = _en || '';
  const bp = product_color._.base_product;
  const _collabo_type = rowTo_CollaboType(product_color);
  return PriceS.price(bp['oem_price' + _collabo_type + _en]);
}

export function calcSizePrice(product_color, _en) {
  const price_info = product_color.price_info || calcPriceInfo(product_color);
  return price_info.sizes_prices[product_color.base_product_size_id]['price' + _en];
}

function getDefaultQuantity(bp_discount_ranges) {
  if (!bp_discount_ranges?.length) return 1;
  const quantity = go(
    bp_discount_ranges,
    find(sel('is_default')),
    (bp_discount_range) => bp_discount_range?.start || bp_discount_ranges[0],
  );
  return quantity;
}

const getLocalizedPrice = ({ max, min, is_range = false }) => {
  const price_str = is_range ? `${min} ~ ${max}` : max;

  if (T.lang === 'kr') return `1개당 ${price_str}`;
  if (T.lang === 'en') return `1EA or more ${price_str}`;
  if (T.lang === 'jp') return `1個 ${price_str}`;
};

export function calcMakerPrice({ pc, is_pure_biz_product, is_not_designed = false }) {
  if (pc.product_faces2?.biz?.quantity || is_pure_biz_product) {
    const quantity =
      pc.product_faces2?.biz?.quantity ||
      getDefaultQuantity(pc._?.base_product?._?.bp_discount_set?._?.bp_discount_ranges);
    const price = go(getProductColorQuantityPrice(pc, quantity), sel('discounted_one'));
    const total_price = UtilS.makeCurrencyPrice(PriceS.pricify(price * quantity), T.lang);
    const one_price = UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang);

    return `${total_price} (${getLocalizedPrice({ max: one_price, is_range: false })})`;
  } else {
    const price = go(calcSizePrice(pc, _en), (price) =>
      UtilS.makeCurrencyPrice(PriceS.pricify(price), T.lang),
    );

    const EDITOR_MIN_PRICES = {
      [VectorEditorConstantS.KEYRING_EDITOR]: BpOptionKeyRingS.KEYRING_MIN_PRICE,
      [VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR]: BpOptionAcrylicFigureS.ACRYLIC_FIGURE_MIN_PRICE,
    };

    const min_price_obj = EDITOR_MIN_PRICES?.[pc._?.base_product?.maker_type]?.[pc._?.base_product.id];

    if (min_price_obj) {
      const min_price = UtilS.makeCurrencyPrice(PriceS.pricify(min_price_obj[T.lang]), T.lang);
      if (BpOptionS.isDesigned(pc) && !is_not_designed) {
        return getLocalizedPrice({ min: min_price, max: price, is_range: false });
      }
      return getLocalizedPrice({ min: min_price, max: price, is_range: min_price !== price });
    } else {
      return `${getLocalizedPrice({ max: price, is_range: false })} ~`;
    }
  }
}

function discountProductPrice(collabo_type, ...args) {
  return makeSaleManager(getProductRanges(collabo_type))(...args);
}

function discountPrintPrice(collabo_type, ...args) {
  return makeSaleManager(getPrintRanges(collabo_type))(...args);
}

function makeDiscountPrices({ lang }, ranges) {
  const _en = lang === 'kr' ? '' : lang === 'en' ? '_en' : '_jp';
  /* bp_discount_set 방식 */
  return go(
    ranges,
    sortByDesc((range) => range.end),
    map((range) => {
      return [range.end, Number(range['price' + _en])];
    }),
    object,
  );
}

function makeDiscountBpOptionPrices({ lang }, selected_bp_option_ids, ranges) {
  const _en = lang === 'kr' ? '' : lang === 'en' ? '_en' : '_jp';
  /* bp_discount_set 방식 */
  return go(
    ranges,
    sortByDesc((range) => range.end),
    map((range) => {
      return [
        range.end,
        go(
          selected_bp_option_ids,
          map((selected_bp_option_id) => {
            const bp_disocunt_option_range = go(
              range._?.bp_discount_range_options,
              reject(({ base_product_face_id }) => base_product_face_id),
              find(
                (bp_discount_range_option) => bp_discount_range_option.bp_option_id === selected_bp_option_id,
              ),
            );
            if (!bp_disocunt_option_range) return 0;
            return Number(bp_disocunt_option_range['price' + _en] || 0);
          }),
          (prices) => PriceS.add(...prices),
          // lang === 'kr' ? (price) => Math.round(price / 10) * 10 : lang === 'jp' ? parseInt : PriceS.price,
        ),
      ];
    }),
    object,
  );
}

function makeDiscountFacePrices({ lang }, { designed_faces, product_color }, ranges) {
  const _en = lang === 'kr' ? '' : lang === 'en' ? '_en' : '_jp';
  const image_price = calcPrintPriceImagePrice(product_color, _en);

  /* bp_discount_set 방식 */
  return go(
    ranges,
    sortByDesc((range) => range.end),
    map((range) => {
      return [
        range.end,
        go(
          designed_faces,
          map((designed_face) => {
            const bp_discount_option_range_price = go(
              range._?.bp_discount_range_options,
              filter(({ base_product_face_id, bp_option_id, is_white_printing_required }) => {
                if (bp_option_id) {
                  if (
                    designed_face.selected_bp_option_ids?.length &&
                    designed_face.selected_bp_option_ids.includes(bp_option_id) &&
                    base_product_face_id === designed_face.bpf_id
                  ) {
                    if (is_white_printing_required) {
                      return product_color._.base_product_color.is_white_printing_required;
                    } else {
                      return !product_color._.base_product_color.is_white_printing_required;
                    }
                  }
                } else {
                  return base_product_face_id === designed_face.bpf_id;
                }
              }),
              sumBy((bp_discount_option_range_price) =>
                Number(bp_discount_option_range_price['price' + _en]),
              ),
            );
            if (!bp_discount_option_range_price) return 0;
            return bp_discount_option_range_price;
          }),
          (prices) => Math.max(range['min_option_price' + _en], PriceS.add(...prices, image_price) || 0),
          // lang === 'kr' ? (price) => Math.round(price / 10) * 10 : lang === 'jp' ? parseInt : PriceS.price,
        ),
      ];
    }),
    object,
  );
}

function makeDiscountSizePrices({ lang }, { base_product_size_id }, ranges) {
  const _en = lang === 'kr' ? '' : lang === 'en' ? '_en' : '_jp';
  /* bp_discount_set 방식 */
  return go(
    ranges,
    map((range) => {
      return [
        range.end,
        go(
          range._?.bp_discount_range_options,
          find((bdro) => bdro.base_product_size_id == base_product_size_id),
          (bdro) => {
            return bdro ? Number(bdro['price' + _en] || 0) : 0;
          },
        ),
      ];
    }),
    object,
  );
}

const getProductRanges = (collabo_type = G.collabo_type) => {
  collabo_type = collabo_type == 'line' ? '' : collabo_type;
  return filter((r) => r.collabo_type == collabo_type, getProductDiscountRanges());
};

const getPrintRanges = (collabo_type = G.collabo_type) => {
  collabo_type = collabo_type == 'line' ? '' : collabo_type;
  return filter((r) => r.collabo_type == collabo_type, getPrintDiscountRanges());
};
