import { html } from 'fxjs/es';
import { OMPCoreUtilF } from '../../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { UtilImageS } from '../../../../Util/Image/S/Function/module/UtilImageS.js';

export const loginmodal = () => {
  const lang = T.lang;
  const device = OMPCoreUtilF.isMobile() ? 'mo' : 'pc';

  const images = {
    custom: {
      kr: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/8ff17798f6f26e0466c5e081fc63d3f0c29bc6ce1.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/7adfa046cbac3567e0cc1ca0054ca81576682ba74.png',
      },
      en: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/85cea8d94dd1c39e5d577933fefeb84d6e3972161.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/99b5004471702a20f454390bf60462f6c63790fb4.png',
      },
      jp: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/70eee160c2ade147528dd1b0724569d81741b48e1.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/2a80eaaa9adeb6d62eb9da2d517e676162665a614.png',
      },
    },
    point: {
      kr: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/62bfe3387d5ca8df5bf8f003cb72ab59a1513ef42.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/e8e8d631522ec2bb70cedad028364de70642264a5.png',
      },
      en: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/c4dfd34450bc1f1bf2e6eca87a833070f4f267b62.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/d83999fd37ebf1e5fe424bca602bc28598dcd7785.png',
      },
      jp: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/5bc74ece9770f5ed03c04eaf3e1848cd3b62b99f2.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/9062b2721c74095fc1363fd55ea1af0206033ff75.png',
      },
    },
    marppleshop: {
      kr: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/b142e8ba9214137cb909e8cab103659b6cbfc6c83.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/8d836fc250edcbdc5b6d84e5c3e8910c47616c326.png',
      },
      en: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/001cdb00e1c28646cc7b01fb77497f9cecef6e883.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/4b9f867be87c41512164a80c186869083038880e6.png',
      },
      jp: {
        pc: '//s3.marpple.co/files/u_3040850/2024/9/original/021098b5722d0ee37e97e8e82cc4df20a0b366c93.png',
        mo: '//s3.marpple.co/files/u_3040850/2024/9/original/6db012f67081cbfdffe7970dddc25695b43fec416.png',
      },
    },
  };
  return html`<div class="auth_login_modal--body">
    <p class="description">${TT('auth::login::modal::description')}</p>
    <div class="images">
      <img src="${UtilImageS.getResized70Webp(images.custom[lang][device], 420)}" alt="benefit" />
      <img src="${UtilImageS.getResized70Webp(images.point[lang][device], 420)}" alt="benefit" />
      <img src="${UtilImageS.getResized70Webp(images.marppleshop[lang][device], 420)}" alt="benefit" />
    </div>
    <div class="auth_login_modal--footer">
      <button class="auth_login_modal--signup">${TT('auth::login::modal::signup')}</button>
      <button class="auth_login_modal--signin">${TT('auth::login::modal::signin')}</button>
    </div>
  </div>`;
};

/**
 * return await MuiF.openFrame(AuthLoginModalMuiF.frame, (frame, page, [tab]) => {
 *   frame.is_modal = !OMPCoreUtilF.isMobile();
 *   frame.animation = true;
 *   frame.hide_frame_button_position = 'RIGHT';
 *   frame.hide_frame_button_type = OMPCoreUtilF.isMobile() ? 'V' : 'X';
 *   frame.el_class = 'auth_login_modal no_header';
 *   frame.appended = $.frame_close_bg;
 *   if (OMPCoreUtilF.isMobile()) {
 *     frame.height = 592;
 *     frame.header_height = G.mp.frame.v_height;
 *   }
 *
 *   tab.makeData = () => ({});
 * });
 */
