import {
  $closest,
  $data,
  $delegate,
  $el,
  $find,
  $findAll,
  $hasClass,
  $hide,
  $qs,
  $qsa,
  $replaceWith,
  $setAttr,
  $trigger,
} from 'fxdom/es';
import { sel, pluck, each, delay, go, map, html } from 'fxjs/es';
import { NewMakerComponentsImageDropBoxF } from '../../../NewMaker/Components/ImageDropBox/F/Function/module/NewMakerComponentsImageDropBoxF.js';
import { NewMakerComponentsImageSelectFrameF } from '../../../NewMaker/Components/ImageSelectFrame/F/Function/module/NewMakerComponentsImageSelectFrameF.js';
import { checkedBox, unCheckedBox } from '../../../OMP/Core/Atom/S/Tmpl/icon.js';
import { OMPCoreFramesStaticStyleF } from '../../../OMP/Core/Frames/StaticStyle/F/Function/module/OMPCoreFramesStaticStyleF.js';
import { OMPCoreFramesTabStyleF } from '../../../OMP/Core/Frames/TabStyle/F/Function/module/OMPCoreFramesTabStyleF.js';
import { OMPCoreUtilF } from '../../../OMP/Core/Util/F/Function/module/OMPCoreUtilF.js';
import { BpOptionConstantS } from '../../S/Constant/module/BpOptionConstantS.js';
import { BpOptionTmplS } from '../../S/Tmpl/module/BpOptionTmplS.js';
import { processAfterSelect, changeVirtualSelect } from './bpoption.js';
import { BpOptionUploadTypeF } from '../../UploadType/F/Function/module/BpOptionUploadTypeF.js';
import { alertProEditorCsError } from '../../../VectorEditor/F/Function/pro_editor.error.js';
import { BpOptionF } from './module/BpOptionF.js';
import { ConfigSentryF } from '../../../Config/Sentry/F/Function/module/ConfigSentryF.js';
import { PrintTemplateF } from '../../../PrintTemplate/F/Function/module/PrintTemplateF.js';
import { PrintTemplateS } from '../../../PrintTemplate/S/Function/module/PrintTemplateS.js';
import { BaseProductS } from '../../../BaseProduct/S/Function/module/BaseProductS.js';
import { NewMakerPropertyBaseProductS } from '../../../NewMaker/Property/BaseProduct/S/Function/module/NewMakerPropertyBaseProductS.js';
import { getBaseProductInMaker } from '../../../Maker/F/getSth.js';
import { NewMakerPropertyBaseProductF } from '../../../NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { BpOptionUploadTypeS } from '../../UploadType/S/Function/module/BpOptionUploadTypeS.js';
import { NewMakerUtilF } from '../../../NewMaker/Util/F/Function/module/NewMakerUtilF.js';
import { NewMakerFramesMobilePdfUploaderF } from '../../../NewMaker/Frames/MobilePdfUploader/F/Function/module/NewMakerFramesMobilePdfUploaderF.js';
import { setDataUploadTypeIsDesigned } from '../../../NewMaker/Util/F/Function/makeDataIsDesigned.js';

const make_img_select_bp_options = (bp_options) =>
  go(
    bp_options,
    map((bp_option) => {
      const { selectors: { img, selected_img } = { selectors: { img: '', selected_img: '' } } } =
        bp_option.preview_meta || {};
      return {
        id: bp_option.id,
        name: bp_option['name' + G._en],
        img,
        selected_img,
      };
    }),
  );

const errorHandling = async (selectEvent, bp_option_groups_el) => {
  try {
    if (G.mp.maker.active()) G.mp.maker.unselect_all();
    await selectEvent();
  } catch (e) {
    console.error(e);
    $.alert(T('maker_error_alert::This is a temporary error. please try again.'));
    bp_option_groups_el.outerHTML = BpOptionTmplS.bpAllOptions({
      product: box().maker.product_color,
    });
    ConfigSentryF.error(e);
    $.don_loader_end();
  }
};

export const event = (tab_el) => {
  return go(
    tab_el,
    // $delegate('click', '.maker-upload-type-mobile.only_svg', () => {
    //   BpOptionUploadTypeF.openMobilePopup();
    // }),

    $delegate('click', '#mp-maker-pdf-file-upload-pop-up', async (e) => {
      if ($hasClass('only_svg', e.currentTarget)) {
        BpOptionUploadTypeF.openMobilePopup();
        return;
      }
      const product_color = box.sel('maker->product_color');
      const bp_id = product_color.base_product_id;

      /* 인쇄용 시안 템플릿 파일 유효성 체크 */

      try {
        const { pdf_printing_file } = await NewMakerFramesMobilePdfUploaderF.openFrame({
          bp_id,
          pdf_printing_file: product_color._.pdf_printing_file,
        });

        if (pdf_printing_file) {
          NewMakerPropertyBaseProductF.pdfFilePossible.setPdf(pdf_printing_file);
          setDataUploadTypeIsDesigned();
          if (pdf_printing_file.id !== product_color.pdf_printing_file_id) {
            $setAttr({ can_save: true }, $qs('span#to_save_changes'));
          }

          go(
            $qsa('.mp_product_detail__main-image'),
            each(async (el) => {
              el.innerHTML = BpOptionUploadTypeS.UPLOAD_TYPE.option.direct.makePdfResultTmpl({
                pdf_printing_file,
                printing_template: product_color._.base_product._.printing_templates?.[0],
              });
            }),
          );
          const fake_mp_maker_el = $qs('.fake_mp_maker');
          if (fake_mp_maker_el) {
            fake_mp_maker_el.outerHTML = html`
              <div class="mp_product_detail__main-image">
                ${BpOptionUploadTypeS.UPLOAD_TYPE.option.direct.makePdfResultTmpl({
                  pdf_printing_file,
                  printing_template: product_color._.base_product._.printing_templates?.[0],
                })}
              </div>
            `;
          }
          $hide($qs('.mp_maker'));
          const bp_option_group_el = BpOptionF.getBpOptionGroupEl(
            BpOptionUploadTypeS.UPLOAD_TYPE.bp_option_group_id,
          );
          BpOptionF.selectBpOption({
            bp_option_group_el,
            selected_bp_option_id: BpOptionUploadTypeS.UPLOAD_TYPE.option.direct.bp_option_id,
          });
          const bp_option_groups_el = $qs('.bp_option_groups');
          const selected_option_group = BpOptionF.makeSelectedOptionGroup(bp_option_groups_el);
          product_color._.selected_option_group = selected_option_group;
        }
      } catch (err) {
        // 에러 핸들링
        ConfigSentryF.makerCapture(err);
        await $.alert(`${T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.')}`);
      }
    }),
    $delegate('click', '#marpplizer .pro-editor .single-upload-file', ({ currentTarget: ct }) => {
      const file = $find('input[type="file"]', ct);
      if (file == null) throw new Error(`Not exist file element`);
      file.click();
    }),
    $delegate('click', '#marpplizer .maker-upload-type-template__download-template button', async (e) => {
      try {
        $.don_loader_start();
        const base_product = box.sel('maker->product_color->_->base_product');
        const bp_id = base_product.id;
        const printing_template =
          G.collabo_type === ''
            ? await PrintTemplateF.getPrintTemplate({
                bp_id,
                lang: T.lang,
              })
            : null;
        if (printing_template == null) {
          /* 프로 에디터 템플릿 처리 */
          const { endpoint_url, file_extension } = await BaseProductS.getProEditorTemplateUrl({
            lang: T.lang,
            base_product_id: base_product.id,
          });
          const file_name = `${base_product[`name${G._en}`]}-TEMPLATE.${file_extension}`;
          OMPCoreUtilF.downloadFileAppCompatible({ url: endpoint_url, file_name });
        } else {
          /* 와우프레스 지류 템플릿 처리 */
          const { file_name, url } = printing_template;
          const template_resource_url = PrintTemplateS.printingTemplateS3UrlToCloudFrontUrl(url);

          OMPCoreUtilF.downloadFileAppCompatible({ url: template_resource_url, file_name });
        }

        await delay(500, () => {});
        $.don_loader_end();
      } catch (err) {
        await alertProEditorCsError({ title: TT('pro_editor::alert::title::download') });
        $.don_loader_end();
        console.error(err);
      }
    }),
    $delegate('click', '#marpplizer .maker-upload-type-template__upload-file button', (e) => {
      go(
        e.currentTarget,
        $closest('.maker-upload-type-template__upload-file'),
        $find('input[type="file"]'),
        (el) => $trigger('click', el),
      );
    }),
    $delegate(
      'change',
      '#marpplizer .maker-upload-type-template__upload-file input[type="file"]',
      async ({ currentTarget: ct }) => {
        if (NewMakerPropertyBaseProductS.pdfFilePossible.isIt(getBaseProductInMaker())) {
          await NewMakerPropertyBaseProductF.pdfFilePossible.fileUploadEvent(ct);
        } else {
          await BpOptionUploadTypeF.uploadTypeDirect.svgUpload(ct);
        }
      },
    ),
    $delegate('click', '#marpplizer .bp_option_group .select_box .option:not(.selected)', async (e) => {
      if ($hasClass('maker-upload-type-selector__is-not-ready-editor', e.currentTarget)) {
        return;
      }
      const bp_option_group_el = $closest('.bp_option_group')(e.currentTarget);
      const bp_option_groups_el = $closest('.bp_option_groups')(e.currentTarget);
      if (
        bp_option_groups_el.dataset.is_designed === 'true' &&
        bp_option_group_el.dataset.is_lockable === 'true'
      )
        return;
      $.don_loader_start();
      const base_product = getBaseProductInMaker();
      const select_el = go(bp_option_group_el, $find('select'));
      const selected_bp_option_id = e.currentTarget.dataset.id;
      if (
        bp_option_group_el.dataset.id == BpOptionUploadTypeS.UPLOAD_TYPE.bp_option_group_id &&
        base_product.maker_type === 'DEFAULT'
      ) {
        const is_go = await NewMakerPropertyBaseProductF.pdfFilePossible.goToEditorType(
          selected_bp_option_id,
        );
        if (!is_go) {
          $.don_loader_end();
          return;
        }
      }
      $.selectable(e);

      await errorHandling(async () => {
        if (G.mp.maker.active()) G.mp.maker.unselect_all();
        const bp_option_group_el = $closest('.bp_option_group')(select_el);
        BpOptionF.selectBpOption({ bp_option_group_el, selected_bp_option_id });
        if (bp_option_group_el.dataset.is_virtual === 'false') {
          await processAfterSelect(select_el);
          $.don_loader_end();
        } else {
          await changeVirtualSelect(bp_option_groups_el);
        }
        NewMakerUtilF.setDataUploadTypeIsDesigned();
      }, bp_option_groups_el);
    }),
    $delegate('click', '.bp_option_group .question_mark', async ({ currentTarget }) => {
      const selected_option_name = go(currentTarget, $closest('.bp_option_group'), $find('option:checked'))
        .dataset.name.replace(/[a-z0-9]+/g, '')
        .trim();

      const { type, information } = $data(currentTarget);

      if (type === BpOptionConstantS.ADDITIONAL_TYPE.IMAGE_MODAL) {
        const img = OMPCoreUtilF.isMobile() ? information.img_mo : information.img;
        OMPCoreFramesStaticStyleF.openStaticInfo({ img });
      } else {
        const option_names = go(
          currentTarget,
          $closest('.bp_option_group'),
          $find('select'),
          $data,
          sel('_.bp_options'),
          pluck(`name${G._en}`),
          map((name) => name.replace(/[a-z0-9]+/g, '').trim()),
        );

        OMPCoreFramesTabStyleF.openInfo({
          tab_names: option_names,
          selected_name: selected_option_name,
          type: information.type,
          info: {
            content: BpOptionF.makeModalHtml,
          },
        });
      }
    }),
    $delegate('click', '.bp_option_group .bp_checkbox_option', async ({ currentTarget }) => {
      const is_checked = currentTarget.dataset.is_checked === 'true';

      $replaceWith($el(!is_checked ? checkedBox() : unCheckedBox()), $find('svg', currentTarget));
      currentTarget.dataset.is_checked = !is_checked;

      go(
        currentTarget,
        $closest('.bp_option_group'),
        $findAll('select option'),
        each((el) => (el.selected = el.dataset.no == !is_checked)),
      );
      const select_el = go(currentTarget, $closest('.bp_option_group'), $find('select'));
      const bp_option_groups_el = $closest('.bp_option_groups')(currentTarget);

      $.don_loader_start();
      await errorHandling(async () => {
        await processAfterSelect(select_el);
        $.don_loader_end();
      }, bp_option_groups_el);
    }),
    $delegate('mousedown', '.bp_option_group select', async (e) => {
      const { currentTarget } = e;
      const bp_option_group_data = $data(currentTarget);
      const {
        ui_type_pc,
        ui_type_mobile,
        _: { bp_options },
      } = bp_option_group_data;
      if (
        ui_type_pc === BpOptionConstantS.UI_TYPE.IMAGE_SELECT ||
        ui_type_mobile === BpOptionConstantS.UI_TYPE.IMAGE_SELECT
      ) {
        e.originalEvent.preventDefault();
        e.originalEvent.stopPropagation();
        const bp_option_group_el = $closest('.bp_option_group', currentTarget);
        const bp_option_groups_el = $closest('.bp_option_groups')(e.currentTarget);
        const selected_bp_option_id = go(currentTarget, $find('option:checked')).dataset.id;
        const select_el = e.currentTarget;
        const afterSelection = async (selected_bp_option_id_from_other_select) => {
          await errorHandling(async () => {
            BpOptionF.selectBpOption({
              bp_option_group_el,
              selected_bp_option_id: selected_bp_option_id_from_other_select,
            });
            if (bp_option_group_el.dataset.is_virtual === 'false') {
              $.don_loader_start();
              await processAfterSelect(select_el);
              $.don_loader_end();
            } else {
              $.don_loader_start();
              await changeVirtualSelect(bp_option_groups_el);
              setTimeout(() => {
                $.don_loader_end();
              }, 5000);
            }
          }, bp_option_groups_el);
        };
        OMPCoreUtilF.isMobile()
          ? await NewMakerComponentsImageSelectFrameF.openImageSelectorFrame(
              {
                selected_bp_option_id,
                options: make_img_select_bp_options(bp_options),
                option_group_name: bp_option_group_data['name' + G._en],
              },
              afterSelection,
            )
          : await NewMakerComponentsImageDropBoxF.openImageDropBox(
              {
                selected_bp_option_id,
                options: make_img_select_bp_options(bp_options),
                element: currentTarget,
              },
              afterSelection,
            );
      }
    }),
    $delegate('change', '.bp_option_group[data-is_virtual="false"] select', async (e) => {
      const bp_option_groups_el = go(e.currentTarget, $closest('.bp_option_groups'));
      const select_el = e.currentTarget;

      $.don_loader_start();
      await errorHandling(async () => {
        await processAfterSelect(select_el);
        $.don_loader_end();
      }, bp_option_groups_el);
    }),
    $delegate('change', '.bp_option_group[data-is_virtual="true"] select', async (e) => {
      const bp_option_groups_el = go(e.currentTarget, $closest('.bp_option_groups'));

      $.don_loader_start();
      await errorHandling(async () => {
        await changeVirtualSelect(bp_option_groups_el);
      }, bp_option_groups_el);
    }),
  );
};
