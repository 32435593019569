import { go, head, identity, last, pluck, sortBy } from 'fxjs/es';
import axios from 'axios';

export const service = {
  getMinMaxQuantities: ({ bp_id, bp_option_ids }) => {
    return go(
      axios.get(`/${T.lang}/@api/bp_discount_set/quantities_prices`, {
        params: {
          bp_id,
          bp_option_ids,
        },
      }),
      ({ data }) => data,
      (quantity_prices) => {
        const quantities = go(quantity_prices, pluck('quantity'), sortBy(identity));
        const min = head(quantities);
        const max = last(quantities);
        return {
          quantities,
          min,
          max,
        };
      },
    );
  },
};
