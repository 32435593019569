import { debounce, go, identity, sel } from 'fxjs/es';
import { OMPCoreLayoutF } from '../../../../../../modules/OMP/Core/Layout/F/Function/module/OMPCoreLayoutF.js';
/*
 * TODO
 * */
ENV.routes_fn_names.forEach(function (routes_fn_name) {
  const f = sel(routes_fn_name, Routes);
  f && f({});
});

!(function (el_html, footer, ham) {
  $.add_class(el_html, G.is_mobile_size() ? 'is_mobile_size' : 'is_pc_size');

  OMPCoreLayoutF.ready();

  G.change_window(isSessionCheckNow);

  go(
    el_html,
    $.on('click', '#mp_layer_pop .one_day_close', function () {
      go($.post('/@api/layer_popup_cookie', {}), function () {
        go($('#mp_layer_pop_fixed_wrapper'), $.fade_out, $.hide); // 디밍이 필요해서 fixed_wrapper 를 사용한 경우
        // go($('#mp_layer_pop'), $.fade_out, $.hide);
      });
    }),
    $.on('click', '#mp_layer_pop .close', function () {
      go($('#mp_layer_pop_fixed_wrapper'), $.fade_out, $.hide); // 디밍이 필요해서 fixed_wrapper 를 사용한 경우
      // go($('#mp_layer_pop'), $.fade_out, $.hide);
    }),
    $.on('click', '#mp_layer_pop2 .one_day_close', function () {
      go($.post('/@api/layer_popup2_cookie', {}), function () {
        go($('#mp_layer_pop_fixed_wrapper2'), $.fade_out, $.hide);
        // go($('#mp_layer_pop2'), $.fade_out, $.hide);
      });
    }),
    $.on('click', '#mp_layer_pop2 .close', function () {
      go($('#mp_layer_pop_fixed_wrapper2'), $.fade_out, $.hide);
      // go($('#mp_layer_pop2'), $.fade_out, $.hide);
    }),

    function () {
      $.on($1('#ham_button'), 'click', function () {
        $.attr(ham, 'on', true);
        $.body_fixed(true);
        // setTimeout(function () {
        // }, 500);
        $.add_class(ham, 'open');
      });
      $.on($1('#ham_close'), 'click', function () {
        if (!$.has_class(ham, 'open')) return;
        $.attr($.remove_class(ham, 'open'), 'on', false);
        $.body_fixed();
        // setTimeout(function () {
        // }, 500);
      });
      // if (G.is_mobile_size())
      //   $.on($1('#ham'), 'click', 'a', () => {
      //     $1('#ham_close').click();
      //   });
    },
  );

  if (G.is_mobile_size()) {
    const scrolled = debounce(function () {
      try {
        if ($.offset($1('#ch-plugin-core > div:last-child')).top > $.offset(footer).top)
          $.add_class($1('#ch-plugin-core'), 'hide');
        else $.remove_class($1('#ch-plugin-core'), 'hide');
      } catch (e) {}
    }, 30);
    $.on(
      location.href.indexOf('/product/detail') != -1 ? $1('#body .for_apple') : window,
      'scroll',
      scrolled,
    );
    setTimeout(scrolled, 500);
  }

  if (ENV.lang == 'kr' && !$.has_class(el_html, 'my_review') && box.sel('is_user->review_alert'))
    go(
      $.post('/@api/user/review_alert') &&
        $.confirm(
          '구매해주셔서 감사합니다!<br>배송이 완료되었다면<br>마플에서 구매한 상품리뷰를 작성해주시고<br>최대 300 포인트 적립 받아가세요!',
        ),
      _p.if2(identity)(function () {
        window.location.href = `/${ENV.lang}/my/review`;
      }),
    );
})($1('html'), $1('#fooer'), $1('#ham'));
