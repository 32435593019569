import { $qs } from 'fxdom/es';
import { compact, each, filter, go, map, pluck, some, sort } from 'fxjs/es';
import { NewMakerCvObjectCvImageF } from '../../NewMaker/CvObject/CvImage/F/Function/module/NewMakerCvObjectCvImageF.js';
import { NewMakerLegacyF } from '../../NewMaker/Legacy/F/Function/module/NewMakerLegacyF.js';
import { NewMakerProductStyleS } from '../../NewMaker/ProductStyle/S/Function/module/NewMakerProductStyleS.js';
import { SVGEditorProductDetailF } from '../../SVGEditor/ProductDetail/F/Function/module/SVGEditorProductDetailF.js';
import {
  hasFullImageCvImage,
  isShadeBp,
  isShadeBpNotBlackAndWhiteContrast,
} from './CvPreview/Shade/render.js';
import { addPfToFcanvasInMaker } from './draw_product_faces.js';
import { getCvObj } from './Fcanvas/cv_object.js';
import { getBaseProductInMaker, getCurrentBpId, getProductColorInMaker, getRealFcanvass } from './getSth.js';
import {
  changeProductColorByBpWithItsPossibleSize,
  initAfterPlacingProductFaces,
  renderNewFacesByCurrentBpcfsInMakerA,
} from './mp_maker.js';
import { hasEmbroInMaker, hasOnlyFlexInMaker } from './overflow.js';
import {
  prevProductMatching2,
  render_prev_product_matcherA,
  unsetPrevProductColor,
} from './prev_product_matcher.js';
import { baseProductChangeInit } from './Product/product_fs.js';
import { openAllFacesModeA, selectFaceInitA } from './select_faces.js';
import { renderProductColorInProductDetailMobile } from './maker_tmpl.js';
import { UtilF } from '../../Util/F/Function/module/UtilF.js';
import { alertWhenIsLocOptimizationResetByBpChange } from './LocOptimization.js';
import { onbeforeunload_event_off, removeAllDesigns } from './util.js';
import { NewMakerBaseProductsEmbroideryConstantS } from '../../NewMaker/BaseProducts/Embroidery/S/Constant/module/NewMakerBaseProductsEmbroideryConstantS.js';
import { NewMakerPropertyBaseProductConstantS } from '../../NewMaker/Property/BaseProduct/S/Constant/module/NewMakerPropertyBaseProductConstantS.js';
import { NewMakerPropertyBaseProductF } from '../../NewMaker/Property/BaseProduct/F/Function/module/NewMakerPropertyBaseProductF.js';
import { BpDiscountSetConstantS } from '../../BpDiscountSet/S/Constant/module/BpDiscountSetConstantS.js';
import { addCvPreview } from './CvPreview/cv_object.js';
import { MakerDebugF } from '../../MakerDebug/F/Function/module/MakerDebugF.js';
import { MakerDebugConstantS } from '../../MakerDebug/S/Constant/module/MakerDebugConstantS.js';

function makePrevProductColor() {
  const all_cv_groups = _p.map(G.mp.maker.all_designs(), function (designs) {
    return designs.length ? G.mp.maker.create_cv_group(designs) : null;
  });
  box.set('maker->prev_cv_groups', all_cv_groups);
  box.set('maker->prev_compact_cv_groups', _p.compact(all_cv_groups));
  box.set('maker->prev_product_color', box.sel('maker->product_color'));
}
function isSimilarPxPrintAreaInMaker() {
  const prev_print_areas2 =
    box().maker.prev_product_color._.base_product_color._.base_product_color_faces[0]._.base_product_face
      .size_faces[0];
  const current_print_areas2 =
    box().maker.product_color._.base_product_color._.base_product_color_faces[0]._.base_product_face
      .size_faces[0];
  const prev_ratio = prev_print_areas2.print.px.width / prev_print_areas2.print.px.height;
  const current_ratio = current_print_areas2.print.px.width / current_print_areas2.print.px.height;
  console.log(
    'current_print_areas2.px_per_1cm - prev_print_areas2.px_per_1cm',
    current_print_areas2.px_per_1cm,
    prev_print_areas2.px_per_1cm,
  );
  return (
    Math.abs(current_print_areas2.px_per_1cm - prev_print_areas2.px_per_1cm) < 5 &&
    Math.abs(prev_ratio - current_ratio) < 0.15
  );
}

function isPrevBpAndCurrentBpSimillar() {
  const prev_face_names = go(
    box().maker.prev_product_color._.base_product._.base_product_colors[0]._.base_product_color_faces,
    pluck('face_name'),
    sort,
  );
  const current_face_names = go(
    box().maker.product_color._.base_product._.base_product_colors[0]._.base_product_color_faces,
    pluck('face_name'),
    sort,
  );

  return JSON.stringify(prev_face_names) === JSON.stringify(current_face_names);
  // return isSimilarPxPrintAreaInMaker() && prev_faces.length === current_faces.length;
}

function isInDetailPageInMobileInMaker() {
  return UtilF.isLegacyMobile() && !$.has_class($1('html.is_mobile_size.maker_page'), 'maker_editor_mode');
}

export function getPrevCurrentSizeFaces() {
  const prev_size_faces = _map(
    _p.range(box.sel('maker->prev_product_color->_->base_product_color->_->base_product_color_faces').length),
    (idx) => G.mp.maker.current_size_face(box().maker.prev_product_color, idx),
  );
  const current_size_faces = _map(
    _p.range(box.sel('maker->product_color->_->base_product_color->_->base_product_color_faces').length),
    (idx) => G.mp.maker.current_size_face(box().maker.product_color, idx),
  );
  return { prev_size_faces, current_size_faces };
}

export async function changeBpByBpidBasic(bp_id) {
  $.css($1('#maker'), { opacity: 0 });
  const bp = await $.get('/@api/maker/base_product', { id: bp_id });
  /* df, design_collection 상품 바꿀때 renderNewFacesByCurrentBpcfsInMakerA */
  await go(
    changeProductColorByBpWithItsPossibleSize(bp),
    () => renderNewFacesByCurrentBpcfsInMakerA(true),
    () => initAfterPlacingProductFaces(),
    () => selectFaceInitA(true),
  );
  const maker_frame_el = $qs('#maker_frame');
  if (maker_frame_el?.dataset?.is_vector === 'true') {
    const bp_option_groups_el = $qs('.bp_option_groups');
    await SVGEditorProductDetailF.vectorEditorPreviewInit(bp_option_groups_el);
  }
  $.css($1('#maker'), { opacity: 1 });
}

export function setProductNameNull(product) {
  product.name = null;
  product.name_en = null;
  product.name_jp = null;
  product.og_image_url = null;
}

export async function changeBpByBpId(bp_id, is_force) {
  try {
    return await _p.go(
      _p.mr($.get('/@api/maker/base_product', { id: bp_id }), G.mp.maker.all_designs()),
      async function (bp, all_designs) {
        /* 상품 변경시 페이지 이동 */
        if (
          G.collabo_type === '' &&
          (bp._?.bp_discount_set?.type === BpDiscountSetConstantS.TYPE.MP_WOW ||
            go(
              bp._.base_products_bp_option_groups,
              map((base_products_bp_option_group) => base_products_bp_option_group?._?.bp_option_group),
              some((bp_option_group) => bp_option_group?.is_virtual),
            ) ||
            go(
              box().maker.product_color._.base_product._.base_products_bp_option_groups,
              map((base_products_bp_option_group) => base_products_bp_option_group?._?.bp_option_group),
              some((bp_option_group) => bp_option_group?.is_virtual),
            )) &&
          $qs('.don_frame[frame_name="mp.product.detail"]')
        ) {
          $.don_loader_start();
          onbeforeunload_event_off();
          location.href = `/${T.lang}/product/detail?bp_id=${bp.id}`;
          return;
        }
        if (
          !is_force &&
          (NewMakerBaseProductsEmbroideryConstantS.PROPERTY.isEmbroideryApp(
            NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
            bp.id,
          ) ||
            NewMakerBaseProductsEmbroideryConstantS.PROPERTY.isEmbroideryApp(
              NewMakerBaseProductsEmbroideryConstantS.PROPERTY.configs,
              box.sel('maker->product_color->base_product_id'),
            ) ||
            ((bp.maker_features?.[NewMakerPropertyBaseProductConstantS.WHEN_CHANGE_REMOVE_DESIGN] ||
              box.sel('maker->product_color')._.base_product.maker_features?.[
                NewMakerPropertyBaseProductConstantS.WHEN_CHANGE_REMOVE_DESIGN
              ]) &&
              !NewMakerPropertyBaseProductF.whenChangeRemoveDesign.canDuplicateBpfDesigns(
                bp,
                box().maker.product_color._.base_product,
              )))
        ) {
          removeAllDesigns();
          all_designs = [];
        }
        box.unset('press_colors');

        const fcanvas = G.mp.maker.editing_canvas();
        if (isShadeBp(bp)) {
          await go(
            getRealFcanvass(),
            map((fcanvas) =>
              go(
                fcanvas._objects,
                filter(
                  (cv_obj) =>
                    cv_obj._data.cv_type === 'cv_pattern' || cv_obj._data.cv_type === 'cv_text_image_pattern',
                ),
              ),
            ),
            filter((arr) => arr.length > 0),
            async (fcanvas_objs) => {
              if (fcanvas_objs.length) {
                await $.alert(
                  T(
                    `maker_error_alert::Pattern is not supported for this product. Pattern image is automatically removed.`,
                  ),
                );
                go(fcanvas_objs, each(each((cv_obj) => cv_obj.remove())));
                all_designs = G.mp.maker.all_designs();
              }
            },
          );
        }
        if (fcanvas) {
          const cv_backgrounds = go(
            getRealFcanvass(),
            map((fcanvas) => getCvObj(fcanvas._objects, 'cv_background')),
            compact,
          );
          if (cv_backgrounds.length) {
            if (
              go(
                cv_backgrounds,
                some((cv_background) => cv_background.fill !== 'rgba(0,0,0,0)'),
              )
            )
              await $.alert(T('maker_error_alert::The selected background color is removed.'));
            go(
              cv_backgrounds,
              each((cv_background) => {
                cv_background.remove();
              }),
            );
          }
          const cv_preview = getCvObj(fcanvas._objects, 'cv_preview');
          if (cv_preview) {
            fcanvas.remove(cv_preview);
            each(
              each((cv_obj) => {
                delete cv_obj._data._visible;
                cv_obj.visible = true;
              }),
              all_designs,
            );
          }
        }
        if (!G.mp.maker.can_embro(bp) && hasEmbroInMaker(all_designs)) {
          await $.alert(T('Embroidery patches are not available for this product'));
          return;
        }
        if (!G.mp.maker.can_flex(bp) && hasOnlyFlexInMaker(all_designs)) {
          await $.alert(T('The font colors or image colors are not available for this product.'));
          return;
        }

        // if (!G.mp.maker.can_flex(bp) && hasOnlyFlexInMaker(all_designs)) {
        //   $.alert(T('The font colors or image colors are not available for this product.'));
        //   return;
        // }
        if (isShadeBpNotBlackAndWhiteContrast(bp) && (await some(hasFullImageCvImage)(all_designs))) {
          await $.alert(
            T(
              'maker::Message::If you do not delete the background image of what you want to engrave, it will be engraved as it is, so be sure to delete the background before uploading.',
            ),
          );
        }

        // $.css($1('#maker'), { opacity: 0 });

        const all_designs_filtered = _p.go(
          all_designs,
          _p.filter(function (designs) {
            return designs.length;
          }),
        );

        /*디자인 하나도 없을때*/
        if (all_designs_filtered.length === 0)
          return _p.go(
            changeProductColorByBpWithItsPossibleSize(bp),
            () => G.mp.maker.extend_state_bp(getCurrentBpId()),
            () => renderNewFacesByCurrentBpcfsInMakerA(true),
            () => {
              if (G.collabo_type !== 'creator') {
                setProductNameNull(box.sel('maker->product_color'));
              }
            },
            /*매칭이 안 뜨는 경우 renderNewFacesByCurrentBpcfsInMakerA */
            () => initAfterPlacingProductFaces(),
            () => selectFaceInitA(true),
          );
        return _p.go(
          ___,
          async function () {
            makePrevProductColor();
            changeProductColorByBpWithItsPossibleSize(bp);
            if (G.collabo_type !== 'creator') {
              setProductNameNull(box.sel('maker->product_color'));
            }
            G.mp.maker.extend_state_bp(getCurrentBpId());
            await renderNewFacesByCurrentBpcfsInMakerA(true);
          },
          function () {
            const is_same = isPrevBpAndCurrentBpSimillar();
            /*디자인한면이 한개 이거나, 비슷한 상품 일때, 라인프렌즈 detail에서 바꿀때*/
            if (
              isInDetailPageInMobileInMaker() ||
              box().maker.prev_compact_cv_groups.length === 1 ||
              is_same
            ) {
              return _p.go(
                ___,
                function () {
                  if (is_same) {
                    return addPfToFcanvasInMaker(box.sel('maker->prev_product_color->product_faces2->value'));
                  }
                  const { prev_size_faces, current_size_faces } = getPrevCurrentSizeFaces();
                  return _p.cmap(box.sel('maker->prev_cv_groups'), function (prev_compact_cv_group, i) {
                    if (!current_size_faces[i] || !prev_compact_cv_group) return;
                    return prevProductMatching2(
                      prev_compact_cv_group,
                      prev_size_faces[i],
                      current_size_faces[i],
                      i,
                    );
                  });
                },
                /*매칭 안 뜨는 경우 renderNewFacesByCurrentBpcfsInMakerA*/
                () => initAfterPlacingProductFaces(),
                async () => {
                  const prev_product_color = box.sel('maker->prev_product_color');
                  await alertWhenIsLocOptimizationResetByBpChange({
                    pf2_values: prev_product_color.product_faces2?.value,
                  });
                },
                unsetPrevProductColor,
                () => selectFaceInitA(true),
                async () => {
                  await go(
                    getRealFcanvass(),
                    each(async (fcanvas) => {
                      await addCvPreview(fcanvas);
                    }),
                  );
                },
              );
            } else {
              return go(
                /*매칭이 뜰 경우 renderNewFacesByCurrentBpcfsInMakerA*/
                initAfterPlacingProductFaces(false, true),
                () => render_prev_product_matcherA(),
                () => openAllFacesModeA(220),
              );
            }
          },
        );
      },
      function () {
        if (isInDetailPageInMobileInMaker()) {
          return _p.go(baseProductChangeInit(), async function () {
            await renderProductColorInProductDetailMobile();
            return _p.go(G.mp.maker.zoom.canvas_container_display_mode(), NewMakerLegacyF.swiperInit);
          });
        }
      },
      function () {
        $.css($1('#maker'), { opacity: 1 });
        if (NewMakerProductStyleS.isNoRequestBgRemoval(getBaseProductInMaker())) {
          NewMakerCvObjectCvImageF.makeRequestBgRemovalFalse();
        }
      },
    );
  } catch (e) {
    MakerDebugF.api.MakerDebugPost(getProductColorInMaker(), e, MakerDebugConstantS.Category.ChangeBp);
    $.alert(T('maker_error_alert::문제가 발생했습니다. 다시 시도해 주세요.'));
  } finally {
    $.don_loader_end();
  }
}
